import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Form_calculator from "./components/form_calculator";
import Form_send_zakaz from "./components/form_send_zakaz";

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/calculator" element={<Form_calculator />} />
              <Route exact="true" path="/send_zakaz" element={<Form_send_zakaz />} />
          </Routes>
      </BrowserRouter>
  );
}

export default App;
