import React from 'react';
import sendForm from "../utility/SendForm";

const FormSendZakaz = () => {
    return (
        <div className="container p-5">
            <form onSubmit={(event) => sendForm(event, null, true, true)} action="sendZakaz" id="sendZakaz" method="POST"
                  className="needs-validation" noValidate>
                <h3>Контактная информация</h3>
                <hr />
                <div className="row">
                    <div className="col-md-4">
                        <input name="fio" placeholder="ФИО" type="text" className="form-control" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="col-md-4">
                        <input name="email" placeholder="Email" type="email" className="form-control" required/>
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="col-md-4">
                        <input name="tel" placeholder="Номер телефона" type="tel" className="form-control" required/>
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                </div>

                <h3 className="pt-4">Общая информация о проекте</h3>
                <hr />
                <textarea className="form-control" name="v1" placeholder="Какова цель создания вашего сайта?" rows="3"></textarea>
                <textarea className="form-control mt-2" name="v2" placeholder="Какие задачи вы хотите решить с помощью этого сайта? (например, продажа товаров, предоставление информации, привлечение клиентов)" rows="3"></textarea>
                <textarea className="form-control mt-2" name="v3" placeholder="Есть ли у вас уже готовый сайт? Если да, то какие функции или элементы вам хотелось бы изменить/улучшить?" rows="3"></textarea>

                <h3 className="pt-4">Функционал сайта</h3>
                <hr />
                <textarea className="form-control" name="" placeholder="Какие ключевые функции должны присутствовать на сайте? Например, каталог товаров, блог, формы обратной связи, личный кабинет, интеграция с социальными сетями и т.п." rows="3"></textarea>
                <textarea className="form-control mt-2" name="v4" placeholder="Нужен ли вам поиск по сайту? Если да, то какой функционал поиска вы ожидаете (поиск по товарам, статьям, услугам)?" rows="3"></textarea>
                <textarea className="form-control mt-2" name="v5" placeholder="Планируете ли вы продавать товары через сайт? Если да, то нужны ли вам такие функции, как корзина покупок, онлайн-платежи, доставка?" rows="3"></textarea>
                <textarea className="form-control mt-2" name="v6" placeholder="Будет ли ваш сайт адаптивным под мобильные устройства?" rows="3"></textarea>

                <h3 className="pt-4">Дизайн и внешний вид</h3>
                <hr />
                <textarea className="form-control" name="" placeholder="У вас есть предпочтения по стилю дизайна? Хотите ли вы использовать минимализм, яркие цвета, анимации и т.д.?" rows="3"></textarea>
                <textarea className="form-control mt-2" name="v7" placeholder="Предпочитаете ли вы современный дизайн или классический подход?" rows="3"></textarea>
                <textarea className="form-control mt-2" name="v8" placeholder="Нужно ли интегрировать логотип компании или другие фирменные элементы в дизайн сайта?" rows="3"></textarea>
                <textarea className="form-control mt-2" name="v9" placeholder="Хотели бы вы, чтобы сайт был похож на сайты конкурентов или наоборот отличался от них?" rows="3"></textarea>

                <h3 className="pt-4">Контент</h3>
                <hr />
                <textarea className="form-control" name="v10" placeholder="Какой контент будет размещаться на вашем сайте? Это будут статьи, новости, описания продуктов, фотографии, видео и т.д.?" rows="3"></textarea>
                <textarea className="form-control mt-2" name="v11" placeholder="Будете ли вы предоставлять материалы для наполнения сайта (тексты, фото, видео), или нам нужно заниматься созданием контента?" rows="3"></textarea>
                <textarea className="form-control mt-2" name="v12" placeholder="Сколько страниц планируется создать? Какую структуру сайта вы представляете?" rows="3"></textarea>

                <h3 className="pt-4">Интеграции и дополнительные сервисы</h3>
                <hr />
                <textarea className="form-control" name="v13" placeholder="Необходимо ли интегрировать сайт с другими сервисами (CRM-системы, платежные шлюзы, системы аналитики и т.д.)?" rows="3"></textarea>
                <textarea className="form-control mt-2" name="v14" placeholder="Требуется ли интеграция с социальными сетями (автоматическая публикация новостей, возможность авторизации через соцсети)?" rows="3"></textarea>
                <textarea className="form-control mt-2" name="v15" placeholder="Важно ли отслеживать статистику посещений и поведение пользователей на сайте? Если да, то нужна ли интеграция с системами веб-аналитики (Google Analytics, Яндекс.Метрика)?" rows="3"></textarea>

                <h3 className="pt-4">Технические требования</h3>
                <hr />
                <textarea className="form-control" name="v16" placeholder="На каком домене будет размещён сайт? Есть ли он уже, или необходимо зарегистрировать новый?" rows="3"></textarea>
                <textarea className="form-control mt-2" name="v17" placeholder="Где будет располагаться хостинг? Или вы предоставите свои серверные мощности?" rows="3"></textarea>
                <textarea className="form-control mt-2" name="v18" placeholder="Насколько важно для вас быстродействие сайта? Есть ли конкретные требования к скорости загрузки страниц?" rows="3"></textarea>
                <textarea className="form-control mt-2" name="v19" placeholder="Необходима ли поддержка мультиязычности? Если да, то сколько языков должно поддерживаться?" rows="3"></textarea>
                <div className="mt-3"></div>

                <div className="alert alert-danger d-none mt-3">

                </div>
                <div className="alert alert-success mt-3 d-none">

                </div>
                <input type="submit" className="btn btn-success w-100" value="Оставить заяку" />
            </form>
        </div>
    );
};

export default FormSendZakaz;