import React from 'react';
import apiRequest from "../utility/ApiRequest";

const FormCalculator = () => {
    return (
        <div>
            Форма Калькулятора
        </div>
    );
};

export default FormCalculator;